<template>
  <div class="studentReport">
    <div class="topCard">
      <tag></tag>
      <div class="title-box">
        <div v-if="$route.query.ruleId > 0" class="new-exam-tag">新高考</div>
        <div class="title">
          {{ topData.name }}
          <div :class="['sub-tag', topData.type == 99 ? 'other' : 'general']">
            {{ getExamType(topData.type) }}
          </div>
        </div>
      </div>
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>返回
      </div>
      <div class="sub-title">
        <div>
          {{ topData.examDate }}&nbsp;&nbsp;&nbsp;&nbsp;<span v-if="level == 2"
            >初</span
          ><span v-if="level == 3">高</span>{{ topData.year }}级
        </div>
      </div>
      <div class="model-title">学生榜单</div>
      <div class="mt10">
        <el-tabs v-model="subjectId" @tab-click="handleChange">
          <el-tab-pane
            v-for="(item, index) in tabs"
            :key="index"
            :label="item.name"
            :name="item.id.toString()"
          ></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <template v-if="propSubject">
      <rank
        :key="propSubject"
        :subject-id="propSubject"
        :has-level-segment="hasLevelSegment"
        :top-data="topData"
        :subject-name="subjectName"
    /></template>
  </div>
</template>
<script>
import { examTypeOptions } from "@/core/util/constdata";
import { getstatsubjectoption } from "@/core/api/academic/common";
import { getstudentreportstudentlisttopinfo } from "@/core/api/academic/student";
import { unionTopData } from "@/core/api/academic/unionReport";
import rank from "./components/rank.vue";
import tag from "../components/tag.vue";
export default {
  name: "Student",
  components: {
    tag,
    rank,
  },
  data() {
    return {
      statId: this.$route.query.id,
      topData: { ...this.$route.query },
      subjectId: "",
      tabs: [],
      propSubject: null,
      level: this.$route.query.level,
      hasLevelSegment: null,
      isDisplayRank: null,
    };
  },
  computed: {
    subjectName() {
      if (!this.subjectId || this.subjectId == 0) {
        return "总分";
      } else {
        return this.tabs.find((item) => item.id == this.subjectId).name;
      }
    },
  },
  mounted() {
    this.getSubjectList();
  },
  methods: {
    async getRankTop() {
      const res = await getstudentreportstudentlisttopinfo({
        id: this.$route.query.id,
      });
      this.topData = res.data.data;
    },
    async getUnionTopData() {
      const res = await unionTopData({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.topData = res.data.data;
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value == type);
        return data.label;
      }
    },
    async getSubjectList() {
      const res = await getstatsubjectoption({
        id: this.statId,
        hasTotal: 1,
        requestPage: 6,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.tabs = res.data.data.map((item) => {
        return { ...item, id: item.id.toString() };
      });
      this.subjectId = this.propSubject = res.data.data[0].id.toString();
      let total = res.data.data.find((item) => item.id == 0);
      this.hasLevelSegment = total.hasLevelSegment;
      if (this.$route.query.examType == 1) {
        this.getUnionTopData();
      } else {
        this.getRankTop();
      }
    },
    handleChange(val) {
      this.propSubject = val.name;
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.studentReport {
  width: 1200px;
  margin: 0 auto;
  .subjects {
    text-align: center;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
::v-deep .el-tabs__active-bar {
  display: none;
}
::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}
::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}

::v-deep .el-icon-arrow-left {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}
::v-deep .el-icon-arrow-right {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}
.model-title {
  padding-top: 20px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  color: #2474ed;
}
</style>
