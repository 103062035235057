<template>
  <div v-loading="loading" class="card">
    <div class="filter">
      <div>
        班级：
        <el-select
          v-model="search.classNum"
          style="width: 192px"
          placeholder="全部班级"
          clearable
          @change="getStudentlistpage"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="search.input"
          style="width: 192px; margin-left: 18px"
          clearable
          placeholder="请输入姓名或考号"
          @input="getStudentlistpage"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <el-button
        type="primary"
        class="text-button-bg"
        :loading="exportLoading"
        @click="exportStudent"
        >导出</el-button
      >
    </div>
    <div v-if="subjectId == 0">
      <el-table
        id="searchBar"
        ref="elTable"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column
          :key="Math.random()"
          prop="studentExamId"
          label="考号"
          width="180"
          align="center"
        />
        <el-table-column
          :key="Math.random()"
          prop="name"
          label="姓名"
          width="80"
          align="center"
        />
        <el-table-column
          :key="Math.random()"
          prop="classNum"
          label="班级"
          width="80"
          align="center"
        />
        <el-table-column
          :key="Math.random()"
          label="操作"
          width="80"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" class="text-button" @click="detail(row)"
              >看学情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="showColumn('classRank')"
          :key="Math.random()"
          prop="classRank"
          label="班级排名"
          width="100"
          align="center"
        />

        <el-table-column
          v-if="showColumn('classRank')"
          :key="Math.random()"
          prop="schoolRank"
          label="校排名"
          width="80"
          align="center"
        />
        <el-table-column
          v-if="showColumn('unionRank') && $route.query.examType == 1"
          prop="unionRank"
          label="整体排名"
          width="100"
          align="center"
        />
        <el-table-column
          v-if="$route.query.examType != 1"
          prop="score"
          label="总分"
          width="100"
          align="center"
        />
        <el-table-column
          :key="Math.random()"
          label="薄弱学科"
          :width="subjects.length > 3 ? 300 : 'auto'"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.littleSubject && row.littleSubject.length">
              <span>{{ row.littleSubject.toString() }}</span>
              稍薄弱
            </span>
            <span
              v-if="
                row.littleSubject &&
                row.seriousSubject &&
                row.littleSubject.length &&
                row.seriousSubject.length
              "
            >
              ,
            </span>
            <span v-if="row.seriousSubject && row.seriousSubject.length">
              <span class="red">{{ row.seriousSubject.toString() }}</span>
              严重偏科
            </span>
          </template>
        </el-table-column>

        <el-table-column
          v-if="hasLevelSegment == 1 && $route.query.examType != 1"
          :key="Math.random()"
          prop="level"
          label="总分等级"
          :width="subjects.length > 3 ? '100' : 'auto'"
          align="center"
        />
        <el-table-column
          :key="Math.random()"
          prop="score"
          label="总分"
          :width="subjects.length > 3 ? '100' : 'auto'"
          align="center"
        />
        <template v-for="(item, index) in subjects">
          <el-table-column
            :key="'subjectName' + index"
            prop="score"
            :label="item.subjectName"
            :width="subjects.length > 3 ? '100' : 'auto'"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.subjects[index].score
            }}</template>
          </el-table-column>
          <el-table-column
            v-if="hasLevelSegment == 1 && $route.query.examType != 1"
            :key="index"
            :label="`${item.subjectName}等级`"
            prop="level"
            :width="subjects.length > 3 ? '100' : 'auto'"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.subjects[index].level
            }}</template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div v-else>
      <el-table
        id="searchBar"
        ref="elTable"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="studentExamId"
          label="考号"
          width="180"
          align="center"
        />
        <el-table-column prop="name" label="姓名" width="80" align="center" />
        <el-table-column
          prop="classNum"
          label="班级"
          width="80"
          align="center"
        />
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" class="text-button" @click="detail(row)"
              >看学情</el-button
            >
          </template>
        </el-table-column>

        <el-table-column
          v-if="showColumn('classRank')"
          prop="classRank"
          label="班级排名"
          width="100"
          align="center"
        />
        <el-table-column
          v-if="showColumn('classRank')"
          prop="schoolRank"
          label="校排名"
          width="80"
          align="center"
        />
        <el-table-column
          v-if="showColumn('unionRank') && $route.query.examType == 1"
          prop="unionRank"
          label="整体排名"
          width="100"
          align="center"
        />
        <el-table-column prop="score" label="得分" width="80" align="center" />
        <el-table-column key="50" label="薄弱情况" align="center">
          <template slot-scope="{ row }">
            <!-- weak 1稍薄弱 0 正常 2严重偏科 -->
            <span v-if="row.weak == 0"> - </span>
            <span v-if="row.weak == 1"> {{ subjectName }}稍薄弱 </span>
            <span v-if="row.weak == 2" class="red">
              {{ subjectName }}严重偏科
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="subjectId != 0"
          v-isUnionShow="'publishSchoolIsAllowTeacherViewAnswerSheet'"
          label="答题卡"
          width="100"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button v-if="row.answerSheetUrl" type="text" @click="saw(row)">
              查看
            </el-button>
            <span v-if="!row.answerSheetUrl">无答题卡</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        :current-page="search.current"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="search.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  studentlistpage,
  exportstudentscore,
} from "@/core/api/academic/student";
import {
  Unionstudentlistpage,
  Unionexportstudentscore,
} from "@/core/api/academic/unionReport";
import { fileDownloadByUrl } from "@/core/util/util";
import { getstatclassoption } from "@/core/api/academic/common";
export default {
  name: "StudentRank",
  props: {
    subjectId: {
      type: [String, null, Number],
      default: null,
    },
    subjectName: {
      type: String,
      default: "",
    },
    hasLevelSegment: {
      type: [Number, String],
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      search: {
        classNum: "",
        current: 1,
        input: "",
        size: 50,
        statId: this.$route.query.id,
      },
      loading: false,
      exportLoading: false,
      options: [],
      tableData: [],
      subjects: [],
      total: 0,
      dialogVisible: false,
      imgList: [],
      tableHeight: 50,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.search.current = 1;
      this.getstatclassoptionList();
      this.getStudentlistpage();
    });
    window.addEventListener("scroll", this.handleScroll, false);
  },
  methods: {
    showColumn() {
      if (this.$route.query.examType == 1) {
        if (this.topData.isSchoolDisplayReportStudentRank == 1) {
          return true;
        } else {
          return false;
        }
      } else {
        // if (this.tableData.length) {
        //   const result = this.tableData.every((item) =>
        //     [null, undefined].includes(item[field])
        //   );
        //   return !result;
        // }
        // return false;
        // 2024/5/9 修改 施秉民族中学
        return this.topData.isDisplayRank == 1;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //滑动的距离
      let heightTop = document.querySelector("#searchBar").offsetTop;
      if (scrollTop >= heightTop) {
        //表头到达页面顶部固定表头
        let top = scrollTop - heightTop + 70;
        document.getElementsByClassName(
          "el-table__header-wrapper"
        )[0].style.position = "relative";
        document.getElementsByClassName(
          "el-table__header-wrapper"
        )[0].style.zIndex = "500";
        document.getElementsByClassName(
          "el-table__header-wrapper"
        )[0].style.top = `${top}px`;
      } else if (scrollTop == 0) {
        //表格横向
        document.getElementsByClassName(
          "el-table__header-wrapper"
        )[0].style.position = "relative";
        document.getElementsByClassName(
          "el-table__header-wrapper"
        )[0].style.zIndex = "500";
      } else {
        this.initHeader();
      }
    },
    initHeader() {
      document.getElementsByClassName(
        "el-table__header-wrapper"
      )[0].style.position = "";
      document.getElementsByClassName("el-table__header-wrapper")[0].style.top =
        "";
      document.getElementsByClassName(
        "el-table__header-wrapper"
      )[0].style.zIndex = "";
    },
    async exportStudent() {
      this.exportLoading = true;
      let res;
      if (this.$route.query.examType == 1) {
        res = await Unionexportstudentscore({
          subjectId: this.subjectId,
          id: this.search.statId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await exportstudentscore({
          subjectId: this.subjectId,
          id: this.search.statId,
        });
      }

      this.exportLoading = false;
      fileDownloadByUrl(res.data.data.url, res.data.data.name);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    saw(item) {
      if (item.answerSheetUrl) {
        sessionStorage.setItem("imgList", item.answerSheetUrl);
        const { href } = this.$router.resolve({
          path: "/academic/card",
          query: {
            name: item.name,
            statId: this.$route.query.id,
            ruleId: this.$route.query.ruleId,
            studentId: item.studentId,
            subjectId: this.subjectId,
          },
        });
        window.open(href, "_blank");
      } else {
        this.$message.error("无可查看答题卡");
      }
    },
    handleSizeChange(val) {
      this.search.size = val;

      this.getStudentlistpage();
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
        });
      });
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getStudentlistpage();
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
        });
      });
    },
    async getStudentlistpage() {
      this.loading = true;
      let res;
      if (this.$route.query.examType == 1) {
        res = await Unionstudentlistpage({
          ...this.search,
          viewSchoolId: this.$route.query.schoolId,
          subjectId: this.subjectId,
        });
      } else {
        res = await studentlistpage({
          ...this.search,
          subjectId: this.subjectId,
        });
      }

      this.loading = false;
      this.total = res.data.data.total;
      this.tableData = res.data.data.records;
      this.subjects =
        res.data.data.records.length && res.data.data.records[0].subjects;
      this.$nextTick(() => {
        this.initHeader();
        this.$refs.elTable.doLayout();
      });
    },
    async getstatclassoptionList() {
      const res = await getstatclassoption({
        id: this.search.statId,
        subjectId: this.subjectId,
        requestPage: 6,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.options = res.data.data;
    },
    detail(row) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          ...this.$route.query,
          studentName: row.name,
          studentId: row.studentId,
          subjectId: this.subjectId,
          classNum: row.classNum,
          level: this.$route.query.level,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-table {
  ::v-deep .hover-row {
    // background-color: #fff5d7 !important;
    td {
      background-color: #fff5d7 !important;
    }
  }
}
.filter {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.red {
  color: #ff3636;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.text-button {
  color: #2474ed !important;
}

.text-button-bg {
  background-color: #2474ed !important;
  border-color: #2474ed !important;
}
</style>
